footer {
    background-color: #1B3058;
    padding: 5em;
    padding-bottom: 40px;
    border-top: 5px solid #FFB400;
    color: #ffffff;
}

.site-footer {
    display: flex;
}

.footer-ati {
    box-sizing: border-box;
    margin-bottom: 20px;
}

.footer-servico .sub-title {
    color: #FFB400;
}

.title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}

a {
    text-decoration: none;
}

.i {
    padding: 0;
}

.sub-title {
    list-style: none;
    margin-bottom: 20px;
    font-weight: 300;
}

.sub-title-a {
    text-decoration: none;

    color: #FFB400;
}

.sub-title-a:hover,
.active {
    color: #ffffff !important;
}
