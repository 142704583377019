
*{
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box
}

.campo {
    /* position: absolute; */
    height: 154px;
    /* background-color: aqua; */
}

.topo-menu {
    padding: 6px 0;
    text-align: center;
    position: relative;
    border-bottom: 1px solid #F0F0F0;
    background: #F4F6F8;
    font-size: 14px;
    line-height: 1.5em;
}

.wrapper {
    max-width: 80% !important;
    margin: auto;
}

.brand_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
}

.navbar-brand-wpz {
    padding: 30px 0;
    order: 1;
    flex: 3;
}

.main-navbar {
    /* margin-bottom: 30px; */
    text-align: center;
    padding: 0;
    min-height: 36px;
    /* position: relative; */
    color: #F0F0F0;
    background-color: #1B3058;
    border-top: solid 5px #FFCB00;
}

#navbar-main{
    list-style: none;
    padding: 0
}

.navbar-child{
    display: inline-block;
    padding: 20px;
}



.imgLogo {
    position: absolute;
    width: 183px;
    height: 42.2px;
    cursor: pointer;
    left: 40px;
    top: 16px;
}



.imgLogo {
    position: absolute;
    width: 183px;
    height: 42.2px;
    cursor: pointer;
    left: 40px;
    top: 16px;
}

.navbar {
    z-index: 99;
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: #006590;
    color: #f8f8f8;
}

.w-container {
    max-width: 1200px;
}

